import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';

// API Imports
import UserService from '../../../api/Users';
import CompanyService from '../../../api/Companies';
import BrandService from '../../../api/Brands';

// Local Imports
import { getControls } from './UserEditPage.helper';
import './UserEditPage.scss';

// Shared & Fixtures imports
import inputChangedHandler from '../../../shared/inputChangeHandler';
import userTypes from '../../../shared/userTypes';
import { validateAllFormFields } from '../../../shared/Form/validation';
import { setProfileDetails } from '../../../store/actions/auth';

const useUserEditPage = () => {
  const dispatch = useDispatch();
  const { id, initialRole } = useParams();
  const [prevId, setPrevId] = useState(null);
  const navigate = useNavigate();

  const {
    userId: currentUserId,
    isAdmin,
    isCompanyAdmin,
    isBountyAdmin,
    isCompanyUser,
    isAdvertiser,
  } = useSelector((state) => state.auth);

  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [showConfirmationModal, setShowConfirmationModal] = useState({
    show: false,
    header: '',
    body: '',
  });

  const [companyOptions, setCompanyOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);

  const [initialLoad, setInitialLoad] = useState(true);

  const [controls, setControls] = useState(null);
  const [role, setRole] = useState(initialRole);
  const [editingSelf, setEditingSelf] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formTouched, setFormTouched] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const [oldEmail, setOldEmail] = useState('');
  const [oldPhoneNumber, setOldPhoneNumber] = useState('');
  const [oldRole, setOldRole] = useState('');

  const getCompanyOptions = useCallback(() => {
    if (companyOptions.length) {
      return;
    }

    setLoading(true);
    CompanyService.options()
      .then(({ data = [] }) => {
        setCompanyOptions(data);
      })
      .catch((err) => {
        const { response } = err;
        const errData = response?.data;
        setToast({
          show: true,
          class: 'error',
          header: errData?.message || 'Couldn’t retrieve company options',
        });
      })
      .finally(() => setLoading(false));
  }, [companyOptions]);

  const getBrandOptions = (companies) => {
    setLoading(true);
    const companyIds = Array.isArray(companies)
      ? companies.map((company) => Number(company?.value ?? company))
      : [companies];

    if (!companyIds.length) {
      setBrandOptions([]);
      setLoading(false);
      return;
    }

    BrandService.brandCompaniesOptions({ company: companyIds })
      .then(({ data = [] }) => {
        setBrandOptions(data);
      })
      .catch(({ response }) => {
        const errMessage = response?.data?.message;
        setToast({
          show: true,
          class: 'error',
          header: errMessage || 'Couldn’t retrieve brand options',
        });
      })
      .finally(() => setLoading(false));
  };

  // On brandOption change filter out selected brands that no longer link to a selected company
  useEffect(() => {
    if (!brandOptions.length || initialLoad) return;
    setControls((previousControls) => {
      const updatedControls = _cloneDeep(previousControls);
      const { brand } = updatedControls;

      if (!brand.value) return updatedControls;

      brand.value = brand.value.filter((b) =>
        brandOptions.some((bOption) => bOption.value === b.value),
      );

      return updatedControls;
    });
  }, [brandOptions, initialLoad]);

  const getUserData = useCallback(async () => {
    try {
      const response = await UserService.get(id);
      const { user } = response.data;
      const isEditingSelf = user.id === currentUserId;
      setEditingSelf(isEditingSelf);

      if (
        !isAdmin &&
        !isBountyAdmin &&
        !isCompanyAdmin &&
        !isCompanyUser &&
        !isEditingSelf
      ) {
        navigate(-1);
      }

      setControls((previousControls) => {
        const updatedControls = _cloneDeep(previousControls);

        const setControl = (controlKey, setDisabled) => {
          if (!updatedControls[controlKey]) return;

          updatedControls[controlKey].valid = true;
          updatedControls[controlKey].value = user[controlKey] ?? null;

          if (setDisabled) {
            updatedControls[controlKey].elementConfig.disabled = true;
          }
        };

        setControl('profileImgUrl');
        setControl('emailOtp');
        setControl('zapierApiKey');

        // Allow admins to edit other user emails but not there own
        setControl('email', !isAdmin || isEditingSelf);
        setControl('name');

        // Allow admin or user to edit own phone number
        setControl('phoneNumber', !(isEditingSelf || isAdmin || isBountyAdmin));

        setOldPhoneNumber(user.phoneNumber);
        setOldRole(user.role);
        setOldEmail(user.email);

        if (user.role === 'ROLE_ADVERTISER') {
          setControl('company');
          setControl('brand');

          if (!isAdvertiser) {
            getCompanyOptions();
            getBrandOptions(user.company);
          }
        }

        if (
          user.role === 'ROLE_COMPANY_ADMIN' ||
          user.role === 'ROLE_COMPANY_USER'
        ) {
          getCompanyOptions();
          // disable company drop-down when logged in user is company admin || company user
          setControl('company', isCompanyAdmin || isCompanyUser);
        }

        if (isAdmin || isBountyAdmin) {
          setControl('role');
        }

        return updatedControls;
      });

      setLoading(false);
      setFormValid(true);
      setRole(user.role);
      setInitialLoad(false);
    } catch (err) {
      console.error(err);
      navigate('/users');
    }
  }, [
    currentUserId,
    getCompanyOptions,
    id,
    isAdmin,
    isAdvertiser,
    isBountyAdmin,
    isCompanyAdmin,
    isCompanyUser,
    navigate,
  ]);

  // Update and init controls
  useEffect(() => {
    setControls((previousControls) => {
      return getControls({
        id,
        role,
        previousControls,
        isAdmin,
        isBountyAdmin,
        companyOptions,
        brandOptions,
        oldRole,
        editingSelf,
      });
    });
  }, [
    brandOptions,
    companyOptions,
    editingSelf,
    id,
    isAdmin,
    isBountyAdmin,
    oldRole,
    role,
  ]);

  // Init page
  useEffect(() => {
    if (id && prevId !== id) {
      setPrevId(id);
      getUserData();
    }

    if (initialLoad && controls) {
      if (!id && !initialRole) {
        navigate(-1);
      }

      // If new user
      if (initialRole) {
        if (
          [
            'ROLE_ADVERTISER',
            'ROLE_COMPANY_ADMIN',
            'ROLE_COMPANY_USER',
          ].includes(initialRole)
        ) {
          getCompanyOptions();
        }
        setLoading(false);
        setInitialLoad(false);
      }
    }
  }, [
    controls,
    getCompanyOptions,
    getUserData,
    id,
    prevId,
    initialLoad,
    initialRole,
    navigate,
  ]);

  /*
   * Check form is valid on every form data change
   * Needed to validate form after role change
   * Cannot be in onInputChangeHandler() as it needs to run after getControls()
   */
  useEffect(() => {
    if (!controls) return;
    const { formIsValid } = validateAllFormFields(controls);
    setFormValid(formIsValid);
  }, [controls]);

  const onInputChangeHandler = (event, inputIdentifier) => {
    setFormTouched(true);
    const isRoleChange = inputIdentifier === 'role';

    let value;
    const isArrayType = ['brand', 'company'].includes(inputIdentifier);

    if (isArrayType || Array.isArray(event)) {
      value = event || [];
    } else {
      if (typeof event === 'string') {
        value = event;
      } else if (typeof event === 'boolean') {
        value = !!event;
      } else {
        value = event?.target?.value || event?.value || '';
      }
      isRoleChange && setRole(value);
    }

    const [updatedControls, formIsValid] = inputChangedHandler(
      value,
      { controls },
      inputIdentifier,
    );

    if (
      isRoleChange &&
      ['ROLE_ADVERTISER', 'ROLE_COMPANY_ADMIN', 'ROLE_COMPANY_USER'].includes(
        event.value,
      )
    ) {
      getCompanyOptions();
    }

    const isCompanyChangeForAdvertiser =
      inputIdentifier === 'company' && role === 'ROLE_ADVERTISER';
    const isRoleChangeToAdvertiser =
      isRoleChange && event.value === 'ROLE_ADVERTISER';

    if (isCompanyChangeForAdvertiser || isRoleChangeToAdvertiser) {
      const companyIds = isCompanyChangeForAdvertiser
        ? value ?? []
        : controls.company.value;
      getBrandOptions(companyIds);
    }

    setControls(updatedControls);
    setFormValid(formIsValid);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    // If new user
    if (!id) {
      setShowConfirmationModal({
        show: true,
        header: 'Invite user confirmation',
        body: `Are you sure you want to invite ${controls.name.value}?`,
      });
      return;
    }

    const checkForChange = (controlKey, oldValue) => {
      if (!controls[controlKey]?.value) return false;
      return controls[controlKey].value !== oldValue;
    };

    const passwordChanged = controls?.password?.value !== '';
    const phoneNumberChanged = checkForChange('phoneNumber', oldPhoneNumber);
    const emailChanged = checkForChange('email', oldEmail);
    const roleChanged = checkForChange('role', oldRole);

    if (
      !phoneNumberChanged &&
      !emailChanged &&
      !passwordChanged &&
      !roleChanged
    ) {
      saveData(true);
      return;
    }

    setShowConfirmationModal({
      show: true,
      header: 'Please confirm the following changes',
      body: (
        <div>
          <p>Are you sure you want to update the:</p>
          <ul>
            {!!passwordChanged && <li>Password</li>}
            {!!phoneNumberChanged && (
              <li>
                Phone number to: <strong>{controls.phoneNumber.value}</strong>
                <ul>
                  <li>
                    This will also update the location for receiving the MFA
                    (multi-factor authentication) code.
                  </li>
                </ul>
              </li>
            )}
            {!!emailChanged && (
              <li>
                Email to: <strong>{controls.email.value}</strong>
                <ul>
                  <li>
                    A confirmation email will be sent to the new email, and the
                    account will be inactive until confirmation
                  </li>
                </ul>
              </li>
            )}
            {!!roleChanged && (
              <li>
                Role to: <strong>{userTypes[controls.role.value]}</strong>
              </li>
            )}
          </ul>
        </div>
      ),
    });
  };

  const saveData = (isUpdateUser) => {
    setShowConfirmationModal({
      show: false,
    });

    let user = {
      name: controls.name.value,
      email: controls.email.value,
      role,
      profileImgUrl: controls.profileImgUrl?.value ?? '',
      emailOtp: controls.emailOtp?.value ?? false,
      zapierApiKey: controls.zapierApiKey?.value ?? '',
    };

    if (['ROLE_COMPANY_ADMIN', 'ROLE_COMPANY_USER'].includes(role)) {
      const companyValue =
        controls.company?.value?.map((c) => c.id || c.value) ?? [];
      user.company = companyValue.map((c) => Number(c.value ?? c));
    }

    if (role === 'ROLE_ADVERTISER') {
      const brandValue = controls.brand?.value ?? [];
      user.brand = brandValue.map((c) => Number(c.value ?? c));
    }

    if (isUpdateUser) {
      user = {
        ...user,
        ...(controls.password?.value && {
          password: controls.password.value,
        }),
        ...(controls.phoneNumber?.value && {
          phoneNumber: controls.phoneNumber.value,
        }),
      };
    }

    const saveFunction = isUpdateUser ? UserService.update : UserService.save;
    saveFunction(user, id)
      .then(() => {
        dispatch(setProfileDetails(user));
        navigate('/users');
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          navigate('/users');
          return;
        }

        const errors = err?.response?.data?._errors;

        if (err?.response?.status === 400 && !errors) {
          let errorMessage = err?.response?.data?.message;

          if (errorMessage?.includes('ER_DUP_ENTRY')) {
            errorMessage = `This user already exists in the system and cannot be given this access. Either edit the existing user if you have access to their profile, or speak to an administrator`;
          } else if (errorMessage) {
            errorMessage = Array.isArray(errorMessage)
              ? errorMessage.join(', ')
              : errorMessage;
          } else {
            errorMessage = 'An unknown error has occurred please try again';
          }

          setToast({
            show: true,
            class: 'error',
            header: errorMessage,
          });
        }

        setErrors(errors ?? {});
        setLoading(false);
      });
  };

  const setErrors = (errors) => {
    const _controls = _cloneDeep(controls);
    Object.keys(errors).forEach((errorKey) => {
      let controlKey = errorKey;

      if (!_controls[controlKey]) {
        setToast({
          show: true,
          class: 'error',
          header: 'An unknown error has occurred please try again',
        });
        return;
      }

      if (controlKey === 'company' && !_controls[controlKey]) {
        controlKey = 'companyOptions';
      }

      _controls[controlKey].error = errors[errorKey];
      _controls[controlKey].touched = true;
    });

    setControls(_controls);
    setFormValid(false);
  };

  const cancel = () => {
    const updatedControls = _cloneDeep(controls);

    if (updatedControls.password && updatedControls.repeatPassword) {
      updatedControls.password.value = '';
      updatedControls.repeatPassword.value = '';
    }

    setShowConfirmationModal(false);
    setLoading(false);
    setControls(updatedControls);
  };

  const title = useMemo(() => {
    return `${id ? 'Edit' : 'Invite'} ${userTypes[role] ?? ''}`;
  }, [id, role]);

  const getters = {
    toast,
    showConfirmationModal,
    title,
    loading,
    formTouched,
    formValid,
    initialLoad,
    controls,
    editingSelf,
    isAdmin,
    isBountyAdmin,
    id,
  };

  const actions = {
    onSubmit,
    onInputChangeHandler,
    cancel,
    navigate,
    setToast,
    saveData,
  };

  return [getters, actions];
};

export default useUserEditPage;
