import React, { useState, useContext } from 'react';
import * as Sections from 'ui-component-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faTrash,
  faArrowsAlt,
  faCode,
} from '@fortawesome/free-solid-svg-icons';
import vidioBanner from '../../../assets/images/vidio-banner.png';
import VidioFormCodeSnippetModal from '../../../pages/CampaignsPage/VidioFormCodeSnippetModal/VidioFormCodeSnippetModal';

import { CampaignCreatorContext } from '../Creator.context';
import './Section.scss';

const Section = (props) => {
  const {
    type,
    config,
    translations,
    removeSection,
    openSettings,
    globalBrandLogoUrl,
    globalSettings,
    customStyle,
    language,
    onLanguageChange,
    availableLanguages,
    videoProvider,
    campaignId,
    sectionId,
    verificationMethod,
  } = props;
  const sectionsTypes = {
    text: Sections.TextParagraph,
    image: Sections.ImageSection,
    header: Sections.Header,
    imagesCarousel: Sections.ImagesCarousel,
    youTube: Sections.YoutubeVideo,
    vidio: Sections.VidioSection,
    postQuestions: Sections.PostQuestions,
  };

  const { campaignData } = useContext(CampaignCreatorContext);
  const [openPQTick, setPQTick] = useState(0);
  const [pqFormState, setPQFormState] = useState({});

  const [showVidioCodeSnippetModal, setShowVidioCodeSnippetModal] =
    useState(false);
  const SectionComponent = sectionsTypes[type] || null;

  const getConfigForLanguage = (sectionConfig, lang) => {
    const defaultLanguageConfig = translations?.[availableLanguages?.[0]];
    const currentLanguageConfig = translations?.[lang];
    return currentLanguageConfig || defaultLanguageConfig || sectionConfig;
  };

  const newConfig = {
    ...getConfigForLanguage(config, language),
    ...pqFormState,
  };

  if (customStyle) {
    newConfig.customStyle = customStyle;
  }
  if (type === 'image') {
    newConfig.src = `${process.env.REACT_APP_UPLOADS_URL}/${newConfig.src}`;
    if (!newConfig.imagePosition) {
      newConfig.imagePosition = 'center center';
    }
  }
  if (type === 'youTube') {
    newConfig.autoplay = '0';
  }
  if (type === 'imagesCarousel') {
    newConfig.slides = newConfig.slides.map((slide, slideKey) => ({
      ...slide,
      imageUrl: `${process.env.REACT_APP_UPLOADS_URL}/${newConfig.slides[slideKey].imageUrl}`,
    }));
  }
  if (type === 'vidio') {
    newConfig.src = vidioBanner;
    newConfig.showOnLoad = false;
    newConfig.slides = newConfig.slides
      ? newConfig.slides.map((slide, slideKey) => ({
          ...slide,
          image: `${process.env.REACT_APP_UPLOADS_URL}/${newConfig.slides[slideKey].image}`,
        }))
      : [];

    const brandLogoUrl = newConfig.companyLogoUrl || globalBrandLogoUrl;
    if (brandLogoUrl) {
      newConfig.brandLogoUrl = `${process.env.REACT_APP_UPLOADS_URL}/${brandLogoUrl}`;
    }
    newConfig.onLanguageChange = onLanguageChange;
    newConfig.videoProvider = videoProvider;
    newConfig.isPhoneRequired = verificationMethod === 'sms';
  }

  if (type === 'postQuestions') {
    // const connectForm = (campaignData?.landingPageContent ?? []).find(
    //   (e) => e.type === 'vidio',
    // );
    // const connectFormTranslations = connectForm?.translations;
    const categorySource = campaignData?.postQuestions?.categories ?? [];
    const selectedQuestionIds = config.selectedQuestionIds ?? [];

    // const companyLogoUrl =
    //   connectFormTranslations?.[language]?.companyLogoUrl ??
    //   connectFormTranslations?.en?.companyLogoUrl ??
    //   '';

    const categoryPreview = {
      introHeading: {
        [language]: newConfig?.introHeading ?? '',
      },
      introMessage: {
        [language]: newConfig?.introMessage ?? '',
      },
      companyImageUrl: campaignData?.companyLogoUrl || '',
      companyName: newConfig?.companyName ?? '',
      categories: [],
    };

    for (const category of categorySource) {
      if (
        selectedQuestionIds.some((id) =>
          category.questions.find((e) => e.questionId === id),
        )
      ) {
        categoryPreview.categories.push({
          id: category.categoryId,
          categoryImgUrl: category.categoryImgUrl,
          categoryLabel: category.categoryLabel,
        });
      }
    }

    newConfig.categoryPreview = categoryPreview;
    newConfig.openPreviewTick = openPQTick;
    newConfig.baseImageUrl = process.env.REACT_APP_UPLOADS_URL;
    newConfig.config = {
      accentColor: '#000000',
      color: globalSettings?.mainColor ?? '#000000',
    };

    newConfig.onOpenPreviewModal = (cpdcId, categoryId) => {
      const category = categorySource.find((c) => c.categoryId === categoryId);
      const selectedQuestions = category.questions.filter((q) =>
        selectedQuestionIds.includes(q.questionId),
      );
      const selectInputs = selectedQuestions
        .filter((q) => q.fieldType === 'select')
        .map((q) => ({
          label: q.questionLabel.en,
          name: q.questionId,
          placeholder: q.placeholder.en,
          answers: q.values.en,
          isMulti: q.multiple,
          required: q.required,
        }));
      const textFields = selectedQuestions
        .filter((e) => e.fieldType === 'text')
        .map((q) => ({
          label: q.questionLabel.en,
          name: q.questionId,
          required: q.required,
        }));
      setPQFormState({
        cpdcId: 'cpdc-id',
        campaignId: 'campaign-id',
        categoryId,
        bountyId: 'bounty-id',
        getDeviceId: () => 'device-id',
        brandName: 'Brand name',
        config: {
          accentColor: globalSettings?.imageBoxColor ?? '#000000',
          color: '#000000',
        },
        previewState: {
          initialised: true,
          introMessage: newConfig?.introMessage ?? '',
          footerMessage: newConfig?.footerMessage ?? '',
          selectInputs,
          textFields,
        },
      });

      setPQTick((tick) => tick + 1);
    };
  }

  return (
    <div>
      {SectionComponent ? (
        <SectionComponent
          {...newConfig}
          language={language}
          availableLanguages={availableLanguages}
          globalSettings={globalSettings}
          id={sectionId}
          previewMode
        />
      ) : (
        <div>
          We could not display section of type <strong>{type}</strong>.
        </div>
      )}
      <div className="section-buttons">
        <FontAwesomeIcon
          icon={faTrash}
          onClick={() => removeSection(props.index)}
          title="Remove module"
          className="section-button section-button--remove"
        />
        {SectionComponent ? (
          <FontAwesomeIcon
            icon={faCog}
            onClick={() => openSettings(props.index)}
            title="Open settings"
            className="section-button section-button--settings"
          />
        ) : null}
        {SectionComponent && type === 'vidio' ? (
          <FontAwesomeIcon
            icon={faCode}
            onClick={() => setShowVidioCodeSnippetModal(true)}
            title="Open settings"
            className="section-button section-button--settings"
          />
        ) : null}
      </div>
      <div className="section-buttons section-buttons--draggable-info">
        <FontAwesomeIcon
          icon={faArrowsAlt}
          title="Move module"
          className="section-button"
        />
      </div>
      {showVidioCodeSnippetModal && (
        <VidioFormCodeSnippetModal
          handleClose={() => setShowVidioCodeSnippetModal(false)}
          campaignId={campaignId}
          formId={sectionId}
        />
      )}
    </div>
  );
};

export default Section;
