import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import OtpInput from 'react18-input-otp';

import * as auth from 'firebase/auth';
import * as actions from '../../store/actions/auth';

import Button from '../UI/Button/Button';
import bountyMediaLogo from '../../assets/images/bounty-media-logo.png';
import PhoneInput from '../UI/PhoneInput/PhoneInput';
import TextLink from '../UI/Button/TextLink';

import { setMultiFactor as setMultiFactorAction } from '../../store/actions/auth';

import styles from './AuthMfaEnroll.module.scss';

const DEFAULT_COUNTRY_CODE = 'id';

const setStateReducer = (state, newValues) => ({
  ...state,
  ...newValues,
});

const setStepReducer = (state, step) => {
  if (!['phoneNumber', 'passcode'].includes(step)) {
    console.warn(`Invalid state given (${step})`);
    return state;
  }
  return step;
};

const footerMessages = {
  phoneNumber:
    'Multifactor authentication is required for your account, please enter your mobile number',
  passcode: 'Enter your passcode to complete multifactor account verification',
};

const recaptchaInstance = () => {
  const recaptchaVerifier = new auth.RecaptchaVerifier(
    'recaptcha-multifactor',
    {
      size: 'invisible',
    },
    auth.getAuth(),
  );
  recaptchaVerifier.render();
  return recaptchaVerifier;
};

const AuthMfaEnroll = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [verificationId, setVerificationId] = useState('');
  const [step, setStep] = useReducer(setStepReducer, 'phoneNumber');

  const [footerMessage, setFooterMessage] = useState(
    footerMessages.phoneNumber,
  );

  const [formState, setFormState] = useReducer(setStateReducer, {
    phoneNumber: '',
    passcode: '',
  });

  const handleInputEvent = (e, inputKey) => {
    const value = e?.currentTarget?.value ?? e;
    setFormState({ [inputKey]: value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setError('');
    switch (step) {
      case 'phoneNumber':
        submitPhone(formState.phoneNumber);
        break;

      case 'passcode':
        submitPasscode(formState.passcode);
        break;

      default: // Intentionally blank
    }
  };

  const submitPhone = async (phoneNumber) => {
    setLoading(true);

    if (phoneNumber.toString().length < 8) {
      setLoading(false);
      return setError('Phone number was too short, please try again');
    }
    const multiFactorUser = auth.multiFactor(auth.getAuth().currentUser);
    const multiFactorSession = await multiFactorUser.getSession();
    const phoneInfoOptions = {
      phoneNumber,
      session: multiFactorSession,
    };

    const phoneAuthProvider = new auth.PhoneAuthProvider(auth.getAuth());
    return phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((_verificationId) => {
        setVerificationId(_verificationId);
        setStep('passcode');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        handleErrorCode(err);
        console.warn(err);
      });
  };

  const handleErrorCode = (_error) => {
    switch (_error.code) {
      case 'auth/requires-recent-login':
        logoutWithError(_error.message);
        break;

      default: // Intentionally blank
    }
  };

  const logoutWithError = (_error) => {
    const userAuth = auth.getAuth();
    auth
      .signOut(userAuth)
      .then(() => {
        dispatch(actions.logout(_error));
        navigate('/');
      })
      .catch(console.error);
  };

  const submitPasscode = (verificationCode) => {
    setLoading(true);

    const phoneAuthCredential = auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );

    const multiFactorAssertion =
      auth.PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
    const multiFactorUser = auth.multiFactor(auth.getAuth().currentUser);

    multiFactorUser
      .enroll(multiFactorAssertion)
      .then(() => {
        setLoading(false);
        dispatch(setMultiFactorAction(false));
        dispatch(actions.phoneVerified(formState.phoneNumber));
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        console.warn(err);
      });
  };

  const onChangePhoneClick = (e) => {
    e.preventDefault();
    // RECAPTCHA BUG: "clear()" isn't unbinding properly and is preventing a refresh
    // https://github.com/szchenghuang/react-google-invisible-recaptcha/issues/17
    // recaptchaVerifier.clear()
    // setRecaptchaVerifier(recaptchaInstance());
    // setStep('phoneNumber');
    window.location.reload(); // This is a quick hack to fix the above recaptcha problem
  };

  useEffect(() => {
    setFooterMessage(footerMessages[step]);
  }, [step]);

  useEffect(() => {
    const _recaptchaVerifier = recaptchaInstance();
    setRecaptchaVerifier(_recaptchaVerifier);
    return () => _recaptchaVerifier?.clear();
  }, []);

  return (
    <div className={styles.authMfaEnrollPage}>
      <div>
        {/* Header */}
        <img
          className={styles.logo}
          src={bountyMediaLogo}
          alt="Bounty Media logo"
        />

        {/* Errors */}
        {error ? (
          <p className={styles.authFormError}>
            <FontAwesomeIcon
              className={styles.icon}
              data-test-id="activation-page-error"
              icon={faExclamationCircle}
            />
            {error}
          </p>
        ) : null}

        {/* Form */}
        <form className={styles.authMfaEnrollForm} onSubmit={submitHandler}>
          <Row className="mb-2">
            <Col>
              {step === 'phoneNumber' && (
                <PhoneInput
                  id="phoneNumber"
                  key="phoneNumber"
                  name="phoneNumber"
                  className={styles.formInput}
                  specialLabel=""
                  disabled={step !== 'phoneNumber'}
                  value={formState.phone}
                  onChange={(value) => handleInputEvent(value, 'phoneNumber')}
                  required
                  enableSearch
                  country={DEFAULT_COUNTRY_CODE}
                  dataTestAttribute="mfa-input-phone"
                />
              )}

              {step === 'passcode' && (
                <OtpInput
                  inputKey="passcode"
                  value={formState.passcode}
                  numInputs={6}
                  onChange={(v) => handleInputEvent(v, 'passcode')}
                  shouldAutoFocus
                  isInputNum
                  hasErrored={!!error}
                  containerStyle={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  inputStyle={{
                    textAlign: 'center',
                    width: '2.15em',
                    height: '2.15em',
                    borderStyle: 'solid',
                    borderColor: '#d56711',
                    borderWidth: '1px',
                    borderRadius: '6px',
                    margin: '0 0.25em',
                  }}
                  focusStyle={{
                    borderColor: 'white',
                    borderWidth: '1px',
                    outlineColor: '#d56711',
                  }}
                />
              )}
            </Col>
          </Row>

          {/* Footer */}
          <p className={styles.subText}>{footerMessage}</p>
          {step === 'passcode' && (
            <p className={styles.subText}>
              <TextLink clicked={onChangePhoneClick}>
                Change mobile number
              </TextLink>
            </p>
          )}

          {/* Recaptcha */}
          <div id="recaptcha-multifactor" />

          {/* Submit */}
          <Button
            id="submit-multifactor"
            additionalClasses={styles.btnDefault}
            isLoading={isLoading}
            clicked={submitHandler}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AuthMfaEnroll;
