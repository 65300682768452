import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ImageSection } from 'ui-component-library';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import ImageLinkerForm from './ImageLinkerForm/ImageLinkerForm';
import CropArea from './CropArea/CropArea';
import LinkArea from './LinkArea/LinkArea';

import inputChangedHandler from '../../../shared/inputChangeHandler';
import { parseToFloat } from '../../../shared/creatorSettings';

import initControls from './controls';
import './ImageLinker.scss';

const coord = {
  top: '45%',
  left: '40%',
  height: '10%',
  width: '20%',
  targetUrl: '',
  title: '',
};

const ImageLinker = React.memo(({ config, imgUrl, onInputChange, onClose }) => {
  const [inputs, setInputs] = useState({ inputs: [], formIsValid: false });
  const [coords, setCoords] = useState([]);
  const [editedArea, setEditedArea] = useState(null);
  const [isTargetUrlEmpty, setIsTargetUrlEmpty] = useState(false);

  useEffect(() => {
    if (config && config.length > 0) {
      const newInputs = [];

      config.map((el) => {
        if (!el) return null;
        const inputConf = {
          ...JSON.parse(JSON.stringify(initControls.coords)),
        };
        Object.keys(el).map((k) => {
          if (inputConf[k]) {
            inputConf[k].value = el[k].replace('%', '');
            inputConf[k].valid = !!el[k];
          }
          return el;
        });
        newInputs.push(inputConf);
        return el;
      });
      setCoords(config);
      setInputs({
        inputs: newInputs,
        formIsValid: true,
      });
    }
  }, [config]);

  const onInputChangeHandler = useCallback(
    (event) => {
      event.preventDefault();

      onInputChange(coords);
    },
    [coords, onInputChange],
  );

  const onAddInputHandler = useCallback(
    (event) => {
      event.preventDefault();

      const inputConf = initControls.coords;

      const newIndex = inputs.inputs?.length;
      setEditedArea(newIndex);

      setInputs({
        inputs: [...inputs.inputs, inputConf],
        formIsValid: false,
      });

      setCoords([...coords, coord]);
    },
    [coords, inputs.inputs],
  );

  const onDeleteInputHandler = useCallback(
    (event, idx) => {
      event.preventDefault();
      const currInputs = [...inputs.inputs];

      if (currInputs && currInputs[idx]) {
        currInputs.splice(idx, 1);

        /* remove coord */
        const currCoords = [...coords];
        if (currCoords[idx]) {
          currCoords.splice(idx, 1);

          setCoords(currCoords);
        }

        let formIsValid = true;
        currInputs.forEach((input) => {
          Object.keys(input).forEach((key) => {
            formIsValid = formIsValid && input[key].valid;
          });
        });
        if (currInputs.length === 0) {
          formIsValid = false;
        }
        setInputs({
          formIsValid,
          inputs: currInputs,
        });
      }
    },
    [coords, inputs.inputs],
  );

  const onLocalInputChange = useCallback(
    (event, idx, field) => {
      const { value } = event.currentTarget;

      const inputsData = [...inputs.inputs];

      const fieldIsNotANumber = field === 'targetUrl' || field === 'title';
      let val = fieldIsNotANumber ? value : `${parseToFloat(value)}`;
      if (!fieldIsNotANumber) {
        if (val > 100) val = 100;
        if (val < 0) val = 100;
      }

      const elementToUpdate = inputsData[idx][field];
      if (elementToUpdate) {
        const [updatedControls, formIsValid] = inputChangedHandler(
          val,
          { controls: inputsData[idx] },
          field,
        );
        if (updatedControls) {
          inputsData[idx] = updatedControls;
          const invalidInputs = inputsData.filter(
            (el) => el.targetUrl?.valid !== true,
          );

          const newInputs = {
            inputs: inputsData,
            formIsValid: formIsValid && invalidInputs?.length < 1,
          };

          const currCoords = [...coords];
          if (!currCoords[idx]) {
            currCoords.splice(idx, 0, coord);
          }

          currCoords[idx] = {
            ...currCoords[idx],
            [field]: fieldIsNotANumber ? val : `${val}%`,
          };
          if (value) setIsTargetUrlEmpty(false);
          setCoords(currCoords);
          setInputs(newInputs);

          return updatedControls;
        }
      }
      return null;
    },
    [coords, inputs.inputs],
  );

  const onCoordChange = (idx, newCoords) => {
    const updateCoords = [...coords];
    updateCoords[idx] = newCoords;

    const updateInputs = [...inputs.inputs];
    updateInputs[idx].height.value = newCoords?.height?.slice(0, -1);
    updateInputs[idx].width.value = newCoords?.width?.slice(0, -1);
    updateInputs[idx].top.value = newCoords?.top?.slice(0, -1);
    updateInputs[idx].left.value = newCoords?.left?.slice(0, -1);
    const newInputs = {
      ...inputs,
      inputs: updateInputs,
    };

    setCoords(updateCoords);
    setInputs(newInputs);
  };

  const onEndEditCoords = (idx) => {
    if (inputs.inputs?.[idx]?.targetUrl?.value) {
      setEditedArea(null);
      return;
    }
    setIsTargetUrlEmpty(true);
  };

  const linkBox = () =>
    coords.map((c, i) => {
      if (i === editedArea) {
        return (
          <CropArea
            key={i.toString()}
            index={i}
            imagePath={imgUrl}
            coord={coords[i]}
            onCoordChange={onCoordChange}
            onEndEditArea={onEndEditCoords}
          />
        );
      }
      return (
        <LinkArea
          key={i.toString()}
          index={i}
          linkBoxMapStyle={{
            cursor: 'pointer',
            borderWidth: 1,
            borderColor: '#ff6f00ff',
            borderStyle: 'solid',
            backgroundColor: 'rgba(255, 111, 0, .2)',
            position: 'absolute',
            width: c.width,
            height: c.height,
            top: c.top,
            left: c.left,
            zIndex: 10,
          }}
          onClick={(index) => setEditedArea(index)}
          dataTestAttribute="image-linker-link-area"
        />
      );
    });

  return (
    <Container>
      <div className="image-linker-container--backdrop" />
      <div className="image-linker-container">
        <div className="image-linker-container__header">
          <h4 className="mb-0">Links coordinates</h4>
          <div className="image-linker-container__close-button">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => onClose()}
              className="creator-settings-section_close"
              title="Close module settings"
            />
          </div>
        </div>
        <Row>
          <Col lg={12}>
            <div className="image-linker-container__inner-wrapper">
              <div className="image-linker-img-container">
                {coords && (
                  <ImageSection
                    src={imgUrl}
                    LinkBoxArea={() => linkBox()}
                    linkBoxesMap={coords}
                    linkBoxMapWrapperStyle={{
                      display: 'inline-flex',
                    }}
                  />
                )}
              </div>
              <ImageLinkerForm
                editedArea={editedArea}
                formIsValid={inputs.formIsValid}
                inputs={inputs.inputs}
                isTargetUrlEmpty={isTargetUrlEmpty}
                onAddInputHandler={onAddInputHandler}
                onDeleteInputHandler={onDeleteInputHandler}
                onInputChangeHandler={onInputChangeHandler}
                onLocalInputChange={onLocalInputChange}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
});

export default ImageLinker;
